<template>
  <div id="overview" class="grid">
    <div class="pg-header">
      <h2>Rapportstatistik</h2>
    </div>
    <div class="pg-search-form">
      <div class="pg-form-group">
        <label class="pg-label">Period</label>
        <v-date-picker v-model="range" is-range>
          <template #default="{ inputValue, inputEvents }">
            <div class="">
              <input :value="inputValue.start" class="pg-input-date-range" readonly v-on="inputEvents.start" />
              <svg class="svg-icon-no-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
              <input :value="inputValue.end" class="pg-input-date-range" readonly v-on="inputEvents.end" />
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="pg-form-group">
        <label class="pg-label"></label>
        <button type="submit" class="btn-primary" @click.prevent="getMyOverview">Hämta</button>
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Snabbval</label>
        <button class="btn-secondary btn-quick" @click.prevent="getMyOverviewThisYear">Innevarande år</button>
        <button class="btn-secondary btn-quick" @click.prevent="getMyOverviewThisMonth">Innevarande månad</button>
      </div>
      <div v-if="errorMessage === '' && !loading">
        <div v-if="errorMessage === '' && !loading && firstSearch && myOverview.overview.length === 0" class="pg-center">
          <p class="infoMessageText">Hittar inga rapportstatistik</p>
        </div>
        <div v-if="myOverview.overview && myOverview.overview.length > 0" class="pg-table">
          <table id="tblOverview" class="content-table">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(overviewgroup, label, index) in myOverview" :key="index">
              <template v-if="index == 0">
                <template v-for="(overview, index2) in overviewgroup">
                  <tr :key="`A-${index2}`" class="table-header1-bold" :class="{ even: index2 % 2, odd: !(index2 % 2) }">
                    <td colspan="2">
                      {{ getMyMonthName(overview.year_month) }}
                      <div class="table-text-small">({{ overview.year_month }})</div>
                    </td>
                  </tr>
                  <tr :key="`B-${index2}`" class="table-header2-bold" :class="{ even: index2 % 2, odd: !(index2 % 2) }">
                    <td>Moment</td>
                    <td>Antal</td>
                  </tr>
                  <template v-for="data in overview.data">
                    <tr :key="data.id" :class="{ even: index2 % 2, odd: !(index2 % 2) }">
                      <td>Delmonterade</td>
                      <td>{{ data.total_partially_assembled_amount }}</td>
                    </tr>
                    <tr :key="data.id + 1" :class="{ even: index2 % 2, odd: !(index2 % 2) }">
                      <td>Slutmonterade</td>
                      <td>{{ data.total_final_assembled_amount }}</td>
                    </tr>
                    <tr :key="data.id + 2" :class="{ even: index2 % 2, odd: !(index2 % 2) }">
                      <td>Slutmonterade ellås</td>
                      <td>
                        {{ data.total_final_assembled_electric_locks_amount }}
                      </td>
                    </tr>
                    <tr :key="data.id + 3" :class="{ even: index2 % 2, odd: !(index2 % 2) }">
                      <td>Sålda boxar</td>
                      <td>{{ data.total_sold_boxes_amount }}</td>
                    </tr>
                  </template>
                </template>
              </template>
              <template v-else-if="index == 1">
                <template v-for="(overviewsum, index3) in overviewgroup">
                  <tr
                    :key="`C-${index3}`"
                    class="table-header1-bold"
                    :class="{
                      even: myOverview.overview.length % 2,
                      odd: !(myOverview.overview.length % 2)
                    }">
                    <td colspan="2">
                      Summering
                      <div class="table-text-small">({{ getSumDateRange() }})</div>
                    </td>
                  </tr>
                  <tr
                    :key="`D-${index3}`"
                    class="table-header2-bold"
                    :class="{
                      even: myOverview.overview.length % 2,
                      odd: !(myOverview.overview.length % 2)
                    }">
                    <td>Moment</td>
                    <td>Antal</td>
                  </tr>
                  <template v-for="data2 in overviewsum.data">
                    <tr
                      :key="data2.id"
                      :class="{
                        even: myOverview.overview.length % 2,
                        odd: !(myOverview.overview.length % 2)
                      }">
                      <td>Delmonterade</td>
                      <td>{{ data2.total_partially_assembled_amount }}</td>
                    </tr>
                    <tr
                      :key="data2.id + 1"
                      :class="{
                        even: myOverview.overview.length % 2,
                        odd: !(myOverview.overview.length % 2)
                      }">
                      <td>Slutmonterade</td>
                      <td>{{ data2.total_final_assembled_amount }}</td>
                    </tr>
                    <tr
                      :key="data2.id + 2"
                      :class="{
                        even: myOverview.overview.length % 2,
                        odd: !(myOverview.overview.length % 2)
                      }">
                      <td>Slutmonterade ellås</td>
                      <td>
                        {{ data2.total_final_assembled_electric_locks_amount }}
                      </td>
                    </tr>
                    <tr
                      :key="data2.id + 3"
                      :class="{
                        even: myOverview.overview.length % 2,
                        odd: !(myOverview.overview.length % 2)
                      }">
                      <td>Sålda boxar</td>
                      <td>{{ data2.total_sold_boxes_amount }}</td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="loading && errorMessage === ''" class="pg-center">
        <Spinner></Spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getMonth, format, startOfMonth, lastDayOfMonth, startOfYear, endOfYear } from 'date-fns';
import Spinner from '@/components/utils/Spinner';

export default {
  name: 'DayReportsOverview',
  components: {
    Spinner
  },
  data() {
    return {
      firstSearch: false,
      loading: false,
      errorMessage: '',
      myOverview: [],
      range: {
        start: startOfMonth(new Date()),
        end: lastDayOfMonth(new Date())
      }
    };
    //   this.$set(this.range);
  },
  computed: {},
  watch: {
    'range.start': function () {
      //console.log("CHANGED!!");
    }
  },
  mounted() {},
  created() {},
  updated() {},
  methods: {
    getMonthName(monthNo) {
      let monthName = '';
      if (monthNo === 1) {
        monthName = 'Januari';
      }
      if (monthNo === 2) {
        monthName = 'Februari';
      }
      if (monthNo === 3) {
        monthName = 'Mars';
      }
      if (monthNo === 4) {
        monthName = 'April';
      }
      if (monthNo === 5) {
        monthName = 'Maj';
      }
      if (monthNo === 6) {
        monthName = 'Juni';
      }
      if (monthNo === 7) {
        monthName = 'Juli';
      }
      if (monthNo === 8) {
        monthName = 'Augusti';
      }
      if (monthNo === 9) {
        monthName = 'September';
      }
      if (monthNo === 10) {
        monthName = 'Oktober';
      }
      if (monthNo === 11) {
        monthName = 'November';
      }
      if (monthNo === 12) {
        monthName = 'December';
      }

      return monthName;
    },
    getMyMonthName(date) {
      return this.getMonthName(getMonth(new Date(date)) + 1);
    },
    getSumDateRange() {
      return format(this.range.start, 'yyyy-MM-dd') + ' - ' + format(this.range.end, 'yyyy-MM-dd');
    },
    ...mapActions({
      fetchDayReportOverview: 'reports-utils/fetchDayReportOverview'
    }),

    async getMyOverviewThisYear() {
      this.range.start = startOfYear(new Date());
      this.range.end = endOfYear(new Date());
      await this.getMyOverview();
    },
    async getMyOverviewThisMonth() {
      this.range.start = startOfMonth(new Date());
      this.range.end = lastDayOfMonth(new Date());
      await this.getMyOverview();
    },
    async getMyOverview() {
      this.firstSearch = true;
      this.loading = true;
      await this.fetchMyDayReportsOverview();
      this.loading = false;
    },
    async fetchMyDayReportsOverview() {
      this.errorMessage = '';
      this.myOverview = [];

      try {
        let data = {
          user_id: this.user_id,
          start_date: this.range.start,
          end_date: this.range.end
        };
        this.myOverview = await this.fetchDayReportOverview(data);
      } catch (error) {
        // console.log("fetchMyOrders ERROR");
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/styles/_variables.scss';
.pg-input-date-range {
  width: 150px;
}
.svg-icon-no-pointer {
  padding-top: 10px;
  width: 1.5em;
  height: 1.5em;
  cursor: default;
}

.btn-quick {
  margin: 0 5px;
  margin-bottom: 8px;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  &.pg-form {
    appearance: none; // Fix appearance for date inputs in Safari
  }
}

@media (max-width: 1690px) {
  .pg-table {
    grid-column: 2/12;
  }
}
@media (max-width: 1270px) {
  .pg-table {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
